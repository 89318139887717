import settings from '@/util/settings'

export function url(pathName, args = {}) {
  let u = urls[pathName]
  for (const [k, v] of Object.entries(args)) {
    u = u.replace(`:${k}`, v)
  }
  return u
}

export function apiUrl(pathName, { args = {} } = {}) {
  let u = apiUrls[pathName]
  for (const [k, v] of Object.entries(args)) {
    u = u.replace(`:${k}`, v)
  }
  return settings.apiUrl + u
}

export function baseUrl() {
  return settings.baseUrl
}

const urls = {
  login: '/api/login',
  user: '/api/user',
}

/**
 * URLs specific to the API; the host is determined by environment in settings.js
 */
const apiUrls = {
  login: '/auth/login',
  signup: '/auth/signup',
  teamInvite: '/auth/invite',
  refreshToken: '/auth/refresh',
  batches: '/batches',
  projects: '/projects',
  batchTests: '/batch-tests',
  report: '/report',
  csvReport: '/csv-report',
  pdfReports: '/pdf-reports',
  reportsDownloaded: '/reports-downloaded',
  me: '/me',
  users: '/users',
  organization: '/me/organization',
  teammembers: '/organization/teammates',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  changePassword: '/user/change-password',
  billingTests: '/billing/tests',
  billingCheckout: '/billing/checkout',
  checkoutSuccess: '/billing/checkout/success',
  assessments: '/assessments',
  completedAssessments: '/assessments/completed',
  generatedReports: '/report/generated',

  cookiePolicy: '/cookie-policy',
  privacyAndTerms: '/latest-pp-tou',
  adminTerms: '/organization/terms-of-understanding/latest/',
  adminPrivacy: '/organization/privacy-policy/latest/',

  respondents: '/respondents',
  respondentsImproved: '/respondents-improved',
  respondentSignup: '/respondents/signup',
  respondentBatch: '/respondents/batch',
  respondentSearch: '/respondents/search',
  anonymizeRespondent: '/respondents/anonymize',
  detailedTests: '/detailed-tests',
  tests: '/tests',
  postResponse: '/responses',
  subTest: '/respondents/subtest/',
  validateTestCode: '/test-codes/validate/',
  validateResumeCode: '/respondents/resume/',
  bookmark: '/respondents/bookmark',
  subtestQuestion: '/respondents/subtest_question',
  testCodes: '/test-codes',
  responses: '/responses',
  searchShortlist: '/respondents/search/shortlist',
  organizationAgreements: '/organization-agreements',
  organizationRespondentAgreements: '/organization/respondent-agreements',

  demographicSurvey: '/demographics-surveys',
  respondentDemographicSurvey: '/demographics-surveys/respondent',
  declineDemographicSurvey: '/demographics-surveys/respondent/decline',
  completeDemographicSurvey: '/demographics-surveys/respondent/complete',
  surveyFlags: '/survey-flags',
  errorLogs: '/logs/front-end-errors',
}
